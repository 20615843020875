<template>
  <div>
    <v-row v-if="isMobile">
      <v-col
        cols="12">
        <div
          ref="stock-table"
          style="height: 600px !important; overflow: scroll;">
          <div class="text-center">
            <v-simple-table
              v-for="(item, index) in noteData"
              :key="`stock-table-body-row${index}`"
              width="100%"
              class="elevation-4 my-4 mx-2 pa-2">
              <tbody>
                <tr>
                  <td
                    width="100px"
                    class="text-left">
                    Code.
                  </td>
                  <td class="text-left">
                    <span class="text-bold text-h6">{{ item.code }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    width="100px"
                    class="text-left">
                    วันที่สร้าง
                  </td>
                  <td class="text-left">
                    {{ $dayjs(item.createdAt).format('DD MMM YYYY | HH:mm:ss') }}
                  </td>
                </tr>
                <tr>
                  <td
                    width="100px"
                    class="text-left">
                    วันที่อัพเดต
                  </td>
                  <td class="text-left">
                    {{ $dayjs(item.updatedAt).format('DD MMM YYYY | HH:mm:ss') }}
                  </td>
                </tr>
                <tr>
                  <td
                    width="100px"
                    class="text-left">
                    Note
                  </td>
                  <td
                    class="note-column text-left">
                    {{ noteCompute(item) }}
                  </td>
                </tr>
                <tr>
                  <td
                    width="100px"
                    class="text-left">
                    Warehouse
                  </td>
                  <td
                    class="note-column text-left">
                    <div
                      v-if="refBy('deliveryNote', item)"
                      class="transfer-chip">
                      <v-chip
                        :class="`warehouse-color-${getLastId(mapNowWarehouseProp(item, 'id'))}`"
                        x-small>
                        {{ mapNowWarehouseProp(item, 'name') || '' }}
                      </v-chip>
                      <v-icon x-small>
                        mdi-arrow-right-bold
                      </v-icon>
                      <v-chip
                        :class="`warehouse-color-${getLastId(mapOtherWarehouseProp(item, 'id'))}`"
                        x-small>
                        {{ mapOtherWarehouseProp(item, 'name') || '' }}
                      </v-chip>
                    </div>

                    <div
                      v-else-if="refBy('withdrawNote', item)"
                      class="transfer-chip">
                      <v-chip
                        :class="`warehouse-color-${getLastId(mapOtherWarehouseProp(item, 'id'))}`"
                        x-small>
                        {{ mapOtherWarehouseProp(item, 'name') || '' }}
                      </v-chip>
                      <v-icon x-small>
                        mdi-arrow-right-bold
                      </v-icon>
                      <v-chip
                        :class="`warehouse-color-${getLastId(mapNowWarehouseProp(item, 'id'))}`"
                        x-small>
                        {{ mapNowWarehouseProp(item, 'name') || '' }}
                      </v-chip>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="100px">
                  </td>
                  <td class="text-right">
                    <v-btn
                      v-if="isCancel"
                      class="mx-1 mt-2"
                      color="error"
                      elevation="0"
                      :loading="item.loading"
                      tile
                      small
                      :disabled="isDisabledByRole"
                      @click="cancel(index)">
                      Cancel
                    </v-btn>
                    <v-btn
                      :to="{
                        name: to,
                        query: {
                          id: item.id,
                          warehouse: warehouseId
                        }
                      }"
                      target="_blank"
                      class="mx-1 mt-2"
                      color="grey lighten-2"
                      elevation="0"
                      tile
                      small>
                      Detail
                    </v-btn>
                    <v-btn
                      v-if="isShowSeparate"
                      :to="{
                        name: 'MoveProducts',
                        query: {
                          noteId: item.id,
                          warehouseId: warehouseId
                        }
                      }"
                      target="_blank"
                      class="mx-1  mt-2"
                      color="primary"
                      elevation="0"
                      tile
                      small>
                      ย้ายสินค้า
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-icon
              v-if="loading"
              class="mdi mdi-loading spin"
              color="black">
            </v-icon>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      v-else
      ref="stock-table"
      v-model="deliveryAndWithdrawSelected"
      :headers="tableHead"
      :items="noteData"
      :items-per-page="-1"
      :show-select="status != 'canceled' && status != 'draft'"
      fixed-header
      :loading="loading"
      :height="500"
      hide-default-footer
      loading-text="กรุณารอสักครู่...">
      <template v-slot:[`item.codeNo`]="{ item }">
        <span class="text-bold">{{ item.code }}</span> <br>
        <span>{{ item.purchaseOrderId }}</span>
      </template>
      <template v-slot:[`item.createDate`]="{ item }">
        {{ $dayjs(item.createdAt).format('DD MMM YYYY | HH:mm:ss') }}
      </template>
      <template v-slot:[`item.updateDate`]="{ item }">
        {{ $dayjs(item.updatedAt).format('DD MMM YYYY | HH:mm:ss') }}
      </template>
      <template v-slot:[`item.note`]="{ item }">
        {{ noteCompute(item) }}
      </template>
      <template v-slot:[`item.autoDeliveryNote`]="{ item }">
        <v-btn
          v-if="item.autoDeliveryNoteId"
          :to="{
            name: 'MoveProducts',
            query: {
              noteCode: item.code,
              autoDeliveryNoteId: item.autoDeliveryNoteId
            }
          }"
          target="_blank"
          class="mx-1"
          elevation="0"
          tile
          text
          small>
          รายละเอียด
        </v-btn>
      </template>
      <template v-slot:[`item.warehouse`]="{ item }">
        <div class="d-flex justify-end align-center">
          <div
            v-if="refBy('deliveryNote', item)"
            class="transfer-chip">
            <v-chip
              :class="`warehouse-color-${getLastId(mapNowWarehouseProp(item, 'id'))}`"
              x-small>
              {{ mapNowWarehouseProp(item, 'name') || '' }}
            </v-chip>
            <v-icon x-small>
              mdi-arrow-right-bold
            </v-icon>
            <v-chip
              :class="`warehouse-color-${getLastId(mapOtherWarehouseProp(item, 'id'))}`"
              x-small>
              {{ mapOtherWarehouseProp(item, 'name') || '' }}
            </v-chip>
          </div>
          <div
            v-else-if="refBy('withdrawNote', item)"
            class="transfer-chip">
            <v-chip
              :class="`warehouse-color-${getLastId(mapOtherWarehouseProp(item, 'id'))}`"
              x-small>
              {{ mapOtherWarehouseProp(item, 'name') || '' }}
            </v-chip>
            <v-icon x-small>
              mdi-arrow-right-bold
            </v-icon>
            <v-chip
              :class="`warehouse-color-${getLastId(mapNowWarehouseProp(item, 'id'))}`"
              x-small>
              {{ mapNowWarehouseProp(item, 'name') || '' }}
            </v-chip>
          </div>
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-end align-center">
          <v-btn
            v-if="isCancel"
            class="mx-1"
            color="error"
            elevation="0"
            :loading="item.loading"
            tile
            small
            :disabled="isDisabledByRole"
            @click="cancel(index)">
            Cancel
          </v-btn>

          <v-btn
            :to="{
              name: to,
              query: {
                id: item.id,
                warehouse: warehouseId
              }
            }"
            target="_blank"
            class="mx-1"
            color="grey lighten-2"
            elevation="0"
            tile
            small>
            Detail
          </v-btn>
          <v-btn
            v-if="isShowSeparate"
            :to="{
              name: 'MoveProducts',
              query: {
                noteId: item.id,
                warehouseId: warehouseId
              }
            }"
            target="_blank"
            class="mx-1"
            color="primary"
            elevation="0"
            tile
            small>
            ย้ายสินค้า
          </v-btn>

          <v-icon
            v-if="status != 'canceled' && status != 'draft'"
            class="mx-1"
            dense
            :color="`${item.print ? 'green' : ''} darken-2`"
          >
            mdi-printer
          </v-icon>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getRole } from '@/assets/js/Authentication'

export default {
  props: {
    height: {
      type: [Number, String],
      default: ''
    },
    noteData: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    warehouseId: {
      type: Number,
      default: 0
    },
    warehouse: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      required: false
    },
    changeTabDeliveryNote: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableHead: [
        {
          text: 'Code No.',
          value: 'codeNo',
          align: 'text-left',
          sortable: false
        },
        {
          text: 'Create Date',
          value: 'createDate',
          align: 'text-left',
          width: 180,
          sortable: false
        },
        {
          text: 'Update Date',
          value: 'updateDate',
          align: 'text-left',
          width: 180,
          sortable: false
        },
        {
          text: 'Note',
          value: 'note',
          align: 'text-left',
          sortable: false
        },
        {
          text: 'Auto Delivery Note',
          value: 'autoDeliveryNote',
          align: 'text-left',
          width: 150,
          sortable: false
        },
        {
          text: 'Warehouse',
          value: 'warehouse',
          align: 'text-left',
          sortable: false,
          width: 150
        },
        {
          text: '',
          value: 'action',
          align: 'text-right',
          sortable: false
        }
      ],
      noteSelected: null,
      deliveryAndWithdrawSelected: []
    }
  },
  computed: {
    isShowSeparate () {
      return this.status === 'approved' && this.warehouseId !== 0 && this.type === 'DeliveryNote'
    },
    isCancel () {
      return this.status === 'pending' || this.status === 'draft'
    },
    isRenew () {
      return this.status === 'canceled'
    },
    isDisabledByRole () {
      const dnRole = [
        'management',
        'area_manager',
        'marketing',
        'developer',
        'creative',
        'merchandising_planner',
        'merchandiser',
        'online_admin',
        'accounting_manager',
        'warehouse_manager',
        'warehouse',
        'store_manager',
        'acting_assist_store_manager',
        'assist_store_manager',
        'sales_staff'
      ]
      const role = getRole()
      return !dnRole.some((r) => r === role)
    }
  },
  watch: {
    deliveryAndWithdrawSelected (newValue) {
      this.deliveryAndWithdrawSelected = newValue
      this.$emit('select-delivery-and-withdraw', this.deliveryAndWithdrawSelected)
    },
    changeTabDeliveryNote () {
      this.deliveryAndWithdrawSelected = []
    }
  },
  mounted () {
    if (this.$refs['stock-table']) {
      if (this.isMobile) {
        this.$refs['stock-table'].addEventListener('scroll', this.handleScroll)
      } else {
        this.$refs['stock-table'].$el.firstElementChild.addEventListener('scroll', this.handleScroll)
      }
    }
  },
  beforeDestroy () {
    if (this.$refs['stock-table']) {
      if (this.isMobile) {
        this.$refs['stock-table'].removeEventListener('scroll', this.handleScroll)
      } else {
        this.$refs['stock-table'].$el.firstElementChild.removeEventListener('scroll', this.handleScroll)
      }
    }
  },
  methods: {
    getSelectedNoteId () {
      return this.noteSelected?.id || null
    },
    getSelectedNoteWarehouseID () {
      return this.noteSelected?.warehouse?.id || null
    },
    isRef (item) {
      return (item.withdrawNoteRef && item.withdrawNoteRef?.id) || (item.deliveryNoteRef && item.deliveryNoteRef?.id)
    },
    refBy (target, item) {
      let result = false
      if (target === 'withdrawNote') {
        result = this.type === 'DeliveryNote' && !!item.withdrawNoteRef
      } else if (target === 'deliveryNote') {
        result = this.type === 'WithdrawNote' && !!item.deliveryNoteRef
      }

      return result
    },
    noteCompute (item) {
      let refs = ''
      if (item.withdrawNoteRef?.code) {
        refs = `Withdraw Note : ${item.withdrawNoteRef?.code}`
      } else if (item.deliveryNoteRef?.code) {
        refs = `Delivery Note : ${item.deliveryNoteRef?.code}`
      }

      let text = ''
      if (item.note && refs) {
        text = `${item.note} | from ${refs}`
      } else if (refs) {
        text = `from ${refs}`
      } else if (item.note) {
        text = item.note
      }

      return text
    },
    mapNowWarehouse (item) {
      if (this.isRef(item)) {
        const nowWarehouse = this.warehouse.find((r) => +r.id === +item.warehouse?.id)
        return nowWarehouse
      }
      return ''
    },
    mapNowWarehouseProp (item, prop = 'id') {
      if (this.isRef(item)) {
        const nowWarehouse = this.warehouse.find((r) => +r.id === +item.warehouse?.id)
        return nowWarehouse ? nowWarehouse[prop] : null
      }
      return null
    },
    mapOtherWarehouse (item) {
      if (this.isRef(item)) {
        if (this.to === 'DeliveryNote') {
          const otherWarehouse = this.warehouse.find((r) => r.id === item.withdrawNoteRef?.warehouseId)
          return otherWarehouse
        }
        if (this.to === 'WithdrawNote') {
          const otherWarehouse = this.warehouse.find((r) => r.id === item.deliveryNoteRef?.warehouseId)
          return otherWarehouse
        }
      }
      return null
    },
    mapOtherWarehouseProp (item, prop = 'id') {
      if (this.isRef(item)) {
        if (this.to === 'DeliveryNote') {
          const otherWarehouse = this.warehouse.find((r) => r.id === item.withdrawNoteRef?.warehouseId)
          return otherWarehouse ? otherWarehouse[prop] : null
        }
        if (this.to === 'WithdrawNote') {
          const otherWarehouse = this.warehouse.find((r) => r.id === item.deliveryNoteRef?.warehouseId)
          return otherWarehouse ? otherWarehouse[prop] : null
        }
      }
      return null
    },
    getLastId (id) {
      const idText = id ? id.toString() : '0'
      if (idText) {
        return idText[idText.length - 1]
      }
      return '0'
    },
    handleScroll (event) {
      this.$emit('scroll', event)
    },
    cancel (index) {
      this.$emit('cancel', index)
    },
    renew (index) {
      this.$emit('renew', index)
    }
  }
}
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr.simple-table-loading td {
  padding: 0;
  height: 4px;
  border: 0 !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: auto;
  vertical-align: top;
  padding-top: 10px;
  padding-bottom: 10px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spin {
  animation: spin 1s linear infinite;
}
.note-column {
  max-width: 650px;
}
</style>
